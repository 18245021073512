import {Component, ElementRef} from '@angular/core';
import {IChangePasswordLabelModel} from '@roosevelt/authentication-lib/lib/change-password/change-password.component';

@Component({
  selector: 'bmt-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent {
  minLength = 12;
  maxLength = 25;
  validationParts = {
    lowercase: new RegExp('.*[a-z].*'),
    uppercase: new RegExp('.*[A-Z].*'),
    number: new RegExp('.*\\d.*'),
    special: new RegExp('.*[$@!%?&^#*,].*')
  };
  labels: IChangePasswordLabelModel = {
    title: '',
    description: '',
    changePasswordBtn: 'Change Password',
    changePasswordFailed: 'Failed to change password, please check requirements and try again.',
    changePasswordSuccess: 'Successfully updated password.',
  };
  constructor(private elRef: ElementRef) {
  }
  ngOnInit(): void {
    setTimeout(() => {
      const changePasswordButton = this.elRef.nativeElement.querySelector('button');
      if (changePasswordButton) {
        changePasswordButton.click();
      }
    }, 0);
  }
}
